import { GrayscalePalette } from '@tcl-boron-colors/colors';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  container: {
    height: 'inherit',
    background: `${GrayscalePalette[200]}`,
    overflowY: 'auto',
  },
  trialDetailsSection: {
    backgroundColor: 'white',
    padding: '16px',
    borderBottom: `1px solid ${GrayscalePalette[300]}`,
  },
  headerV2: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 600,
    color: 'black',
  },
  twoColumns: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 1fr',
    gap: '16px',

    '& > div': {
      gridColumn: 'span 1',
    },

    '@media (max-width: 1100px)': {
      gridTemplateColumns: '1fr',
      '& > div': {
        gridColumn: 'span 1',
      },
    },
  },
  twoColumnsV2: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  matchUpdateSection: {
    padding: '16px',
    overflowY: 'scroll',
  },
  trialMatchCard: {
    margin: '16px 0',
    height: 'auto',
    padding: '16px',
    fontSize: '14px',
    lineHeight: '20px',
    backgroundColor: 'white',
    boxShadow: '0px 1px 3px 1px rgba(0, 0, 0, 0.1)',

    '& > div > div': {
      overflowY: 'hidden',
      padding: '0 0 4px 0',
    },
  },
  header: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 600,
    marginBottom: '16px',
  },
  detailsHeader: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 600,
    marginBottom: '16px',
  },
  matchStatusFlags: {
    display: 'flex',
    gap: '8px',
    marginBottom: '16px',
    alignItems: 'center',
  },
  matchDetailsCard: {
    backgroundColor: GrayscalePalette[200],
    padding: '16px',
    boxShadow: 'none',

    '& > div > div': {
      overflowY: 'hidden',
      paddingLeft: '2px',
      paddingBottom: '3px',
    },
  },
  whyThisStatus: {
    '& > textarea': {
      width: '100%',
    },
  },
  collapseContainer: {
    padding: '10px',
    borderRadius: '10px',
    backgroundColor: GrayscalePalette[200],

    '&:not(:last-child)': {
      marginBottom: '8px',
    },
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
    paddingBottom: 10,
  },
  label: {
    fontWeight: 'bold',
    fontSize: 14,
    marginBottom: 2,
  },
  leftColumn: {
    flex: '1',
    marginRight: 20, // Adjust this to increase/decrease spacing
  },
  rightColumn: {
    flex: '1',
  },
  noteRow: {
    marginTop: 10,
  },
  textArea: {
    marginBottom: '16px',

    '& > textarea': {
      width: '100%',
      height: '100px',
    },
  },
  mutations: {
    '& > textarea': {
      height: '50px',
    },
  },
  internalNote: {
    marginTop: '20px',
    marginBottom: '16px',
  },
  toggle: {
    padding: 0,
    margin: '6px 0 8px 0',
  },
  matchFlags: {
    display: 'flex',
    gap: '16px',

    '&:not(:last-child)': {
      marginBottom: '16px',
    },
  },
  matchOverviewFlags: {
    marginBottom: '16px',
  },
  borderedToggle: {
    padding: '4px 8px 4px 16px',
    border: '1px solid rgba(196, 196, 197, 1)',
    borderRadius: '10px',
  },
  divider: {
    height: '1px',
    width: '100%',
    margin: '16px 0',
    backgroundColor: `${GrayscalePalette[400]}`,
  },
  newStatusButtons: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
    alignItems: 'center',
  },
  candidateNowStatuses: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    marginTop: '16px',
  },
  candidateNowStatusHeader: {
    marginBottom: '8px',
  },
  matchStatusCards: {
    marginTop: '16px',
  },
  headerRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  saveExit: {
    display: 'flex',
    justifyContent: 'space-between',
    background: `${GrayscalePalette[600]}`,
    color: `${GrayscalePalette[100]}`,

    '& > div': {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      padding: '16px 24px',

      '&:hover': {
        background: `${GrayscalePalette[500]}`,
        cursor: 'pointer',
      },
    },
  },
  candidateNowButton: {
    backgroundColor: `${GrayscalePalette[300]}`,
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.5,
  },
  saving: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  subsequentRow: {
    marginTop: '16px',
  },
});

export const useCollapseStyles = createUseStyles(
  {
    titleWrapper: {
      justifyContent: 'flex-start',
    },
    contentWrapper: {
      paddingRight: 0,
    },
    title: {
      fontWeight: 400,
      letterSpacing: '2px',
      textTransform: 'uppercase',
      fontSize: '14px',
      lineHeight: '24px',
      letter: '2px',
    },
    container: {
      paddingRight: 0,
      border: '1px solid #C4C4C5',
    },
  },
  {
    index: 9999,
  },
);
