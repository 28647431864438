import typography from '@tcl-boron-styles/typography/dist/index.module.scss';
import { PatientCohortMatchWorkflowState, SnoozeDataType } from '@tempus/stateflow-types';
import { Activity, MatchStatusUpdates } from '@tempus/t-shared';
import { PATIENT_TRACKER_STATUSES } from '@tempus/t-shared/src/constants/patient-tracker';
import cn from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '~/store';
import { getPatientCohortMatchWorkflowStateToDisplay } from '~/store/patientTrackerCommons/constants';
import { StoreKeys } from '~/store/patientTrackerCommons/types';
import { formatDateForDisplay } from '~/utils/misc';
import { getTrialDisplay } from '~/utils/trial';

import useStyles from './ActivityStatus.styles';

export const MatchStatusUpdateActivity = ({ storeKeys, activity }: { storeKeys: StoreKeys; activity: Activity }) => {
  const classes = useStyles();
  const content = activity.content as MatchStatusUpdates;

  const ptds = useSelector(
    ({ [storeKeys.store]: patientTracker }: RootState) =>
      patientTracker[storeKeys.stateMapKey].patient.data?.patientTrackingDetails,
  );

  const getArmNameToDisplay = () => {
    const arm = content.armId ? ptds?.find((ptd) => ptd.arm?.id === content.armId)?.arm : null;
    const armName = arm?.name || '';
    return armName ? `, ${armName}` : '';
  };

  const displayWatchlistUntilText = () => {
    if (content.newStatus === PatientCohortMatchWorkflowState.WATCHLIST && content.snoozeData) {
      if (content.snoozeData?.snoozeType === SnoozeDataType.NextVisitDatePassed) {
        return ' until next visit';
      }

      return ` until ${formatDateForDisplay(content.snoozeData?.snoozeDate, true)}`;
    }

    return '';
  };

  return (
    <li key={activity.id} className={cn(typography.body, classes.li)}>
      <div className={classes.listItemContainer}>
        <div className={classes.updatesLeft}>
          <div>
            <strong>
              {getTrialDisplay(content.trialId, ptds || [])}
              {getArmNameToDisplay()}
            </strong>
            {` match status updated to `}
            <em>{getPatientCohortMatchWorkflowStateToDisplay(content.newStatus as PatientCohortMatchWorkflowState)}</em>
            {displayWatchlistUntilText()}.
          </div>
          {(content.newStatus === PATIENT_TRACKER_STATUSES.NO_LONGER_A_CANDIDATE ||
            content.newStatus === PatientCohortMatchWorkflowState.NO_LONGER_A_CANDIDATE) && (
            <>
              <div>Reason: {content.reasonNotAMatch}</div>
              {content.reasonNotAMatchDetails && <div>Additional details: {content.reasonNotAMatchDetails}</div>}
            </>
          )}

          {content.additionalDetails && (
            <div className={classes.additionalDetails}>
              <div className={classes.noteBody}>
                <div className={classes.noteText}>{content.additionalDetails}</div>
                <span className={cn(typography.supportingBody, typography.gray)}>- {activity.userName}</span>
              </div>
            </div>
          )}
        </div>
        <div>{activity.createdAt}</div>
      </div>
    </li>
  );
};
