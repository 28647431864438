import { ChevronDown, Edit } from '@tcl-argon-icons/icons'; // Replace with correct icon set
import { GrayscalePalette } from '@tcl-boron-colors/colors';
import { Button } from '@tcl-boron-prefabs/button'; // Adjust as needed
import { Menu } from '@tcl-boron-prefabs/menu';
import { storeActions } from '@tempus/t-shared/ui';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as XLSX from 'xlsx';

import { RootState } from '~/store';
import api from '~/store/api';

import { PatientTrackerBulkUpdates } from './types';

const BulkEditMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const currentUserId = useSelector(({ user }: RootState) => user.user?.uid);
  const dispatch = useDispatch();
  const generateCSVTemplate = async () => {
    try {
      const res = await api.patientTrackerV2.getPatientTrackerReportTemplate();
      const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'PatientTrackerTemplate.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      dispatch(storeActions.notification.showErrorMessage('Error downloading the template:', error));
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      if (!e.target || !e.target.result) {
        return;
      }
      const data = new Uint8Array(e.target.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: 'array', cellDates: true });

      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData: PatientTrackerBulkUpdates[] = XLSX.utils.sheet_to_json(sheet);
      try {
        await api.patientTrackerV2.uploadBulkPatientTrackerReport(jsonData, currentUserId);
        dispatch(storeActions.notification.showSuccessMessage('Bulk update completed'));
      } catch (e) {
        dispatch(storeActions.notification.showErrorMessage('Bulk update failed'));
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const handleMenuAction = (menuItemId) => {
    if (menuItemId === 'download-template') {
      generateCSVTemplate();
    } else if (menuItemId === 'upload-update') {
      document.getElementById('file-upload')?.click();
    }

    setIsOpen(false);
  };

  const menuItems = [
    [
      {
        text: 'Download SF PTR template',
        id: 'download-template',
      },
      {
        text: 'Upload SF PTR update',
        id: 'upload-update',
      },
    ],
  ];

  return (
    <div style={{ width: 'max-content', position: 'relative' }}>
      <input
        type="file"
        id="file-upload"
        accept=".xlsx, .xls, .csv"
        style={{ display: 'none' }}
        onChange={handleFileUpload}
      />

      <Menu onClose={() => setIsOpen(false)} menuItems={menuItems} isOpen={isOpen} onChange={handleMenuAction}>
        <Button
          onClick={() => setIsOpen(!isOpen)}
          onBlur={() => setIsOpen(false)}
          ariaLabel={''}
          style={{
            backgroundColor: `${GrayscalePalette[200]}`,
            color: '#1F2937',
            border: '2px solid #1F2937',
            display: 'flex',
            alignItems: 'center',
            fontSize: '14px',
            transition: 'border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
          }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <Edit />
            <span>Bulk edit</span>
            <ChevronDown />
          </div>
        </Button>
      </Menu>
    </div>
  );
};

export default BulkEditMenu;
