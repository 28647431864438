import { PatientCohortMatchWorkflowState } from '@tempus/stateflow-types';
import { PATIENT_TRACKER_STATUSES } from '@tempus/t-shared/src/constants/patient-tracker';
import { get } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { Edit } from 'tcl-v3/icons';

import { RootState } from '~/store';
import { getPatientCohortMatchWorkflowStateToDisplay } from '~/store/patientTrackerCommons/constants';
import { formatDateForDisplay, parseEscapedCharacters } from '~/utils/misc';

import useStyles from './InactiveMatchesTab.styles';

export default ({ match, editMatch }) => {
  const classes = useStyles();
  const isInternalUser = useSelector(
    (state: RootState) => state.user.canViewAllPatientTracking && state.user.canWritePatientTracking,
  );
  const [showMoreDetails, setShowMoreDetails] = React.useState(true);
  const IS_UTC_TIME = true;

  return (
    <div key={match.trial.id} className={classes.match}>
      <Edit className={classes.editIcon} onClick={() => editMatch(match.workflowId)} />
      <div className={classes.trialTitle}>{get(match, 'trial.shortName')}</div>
      <div>{get(match, 'arm.name')}</div>
      <div className={classes.statusRow}>
        <div>
          <i>{getPatientCohortMatchWorkflowStateToDisplay(match.status)}</i>
          {(match.status === PATIENT_TRACKER_STATUSES.NO_LONGER_A_CANDIDATE ||
            match.status === PatientCohortMatchWorkflowState.NO_LONGER_A_CANDIDATE) &&
            ` - ${parseEscapedCharacters(match.reasonNotAMatch)}`}
        </div>
        {match.status === PATIENT_TRACKER_STATUSES.ENROLLED ||
        match.status === PatientCohortMatchWorkflowState.ENROLLED ? (
          <div>
            {Boolean(match.firstTreatmentDate) && `${formatDateForDisplay(match.firstTreatmentDate, IS_UTC_TIME)}`}
          </div>
        ) : (
          <div>{Boolean(match.statusDate) && `${formatDateForDisplay(match.statusDate, IS_UTC_TIME)}`}</div>
        )}
      </div>
      {(match.status === PATIENT_TRACKER_STATUSES.NO_LONGER_A_CANDIDATE ||
        match.status === PatientCohortMatchWorkflowState.NO_LONGER_A_CANDIDATE) && (
        <div>{parseEscapedCharacters(match.reasonNotAMatchDetails)}</div>
      )}
      {!showMoreDetails && (
        <div className={classes.viewLink} onClick={() => setShowMoreDetails(true)}>
          + View more details
        </div>
      )}
      {showMoreDetails && (
        <>
          <div className={classes.row}>
            <b>About the match</b>
          </div>
          <div>
            {Boolean(match.tempusToPortalLatestPortalPatientUpdate) ? (
              parseEscapedCharacters(match.tempusToPortalLatestPortalPatientUpdate)
            ) : (
              <span className={classes.naText}>n/a</span>
            )}
          </div>
          <div className={classes.statusRow}>
            <div>Matching biomarkers</div>
            <div>
              {Boolean(match.mutations) ? (
                parseEscapedCharacters(match.mutations)
              ) : (
                <span className={classes.naText}>n/a</span>
              )}
            </div>
          </div>
          {isInternalUser && (
            <>
              <div className={classes.row}>
                <b>T-app screening note (Tempus internal view only)</b>
              </div>
              <div>
                {Boolean(match.tAppNotes) ? (
                  parseEscapedCharacters(match.tAppNotes)
                ) : (
                  <span className={classes.naText}>n/a</span>
                )}
              </div>
            </>
          )}
          <div className={classes.statusRow}>
            <div>
              <i>Consented</i>
            </div>
            <div>
              {Boolean(match.patientConsentedDate) ? (
                formatDateForDisplay(match.patientConsentedDate, IS_UTC_TIME)
              ) : (
                <span className={classes.naText}>n/a</span>
              )}
            </div>
          </div>
          {isInternalUser && (
            <div className={classes.statusRow}>
              <div>
                <i>Consent recognized by Tempus</i>
              </div>
              <div>
                {Boolean(match.dateConsentRecognizedByTempus) ? (
                  formatDateForDisplay(match.dateConsentRecognizedByTempus, IS_UTC_TIME)
                ) : (
                  <span className={classes.naText}>n/a</span>
                )}
              </div>
            </div>
          )}
          <div className={classes.viewLink} onClick={() => setShowMoreDetails(false)}>
            - View less
          </div>
        </>
      )}
    </div>
  );
};
